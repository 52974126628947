.main{
    overflow: hidden;
}

.left_bg_image{
    background-image: url('../Images/chacter_poster_v002_t001.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    margin: 0px !important;
    padding: 0px !important;
}

.overlay-col{
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    width: 100%;
}

.login{
    position: relative;
    background-color: #fff;
}

.login_model{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

/* Login Page Two CSS */

.main_wrapper{
    background-image: url('../Images/chacter_poster_v002_t001.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    color: #fff !important;
    z-index: 500;
}

.Overlay_Login{
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    position: relative;
    backdrop-filter: blur(2px);
}

.model_login{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    z-index: 500;
}

.input{
    border-radius: 30px;
}