/**
 * = Paginations
 */
 .circle-pagination{
    .page-link,
    span{
        @include display-flex();
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        padding: 0;
        @include border-radius($circle-radius);
    }
}

.css-dmuikh-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff !important;
    border: #7A4BFF !important;
    background-color: #7A4BFF !important;
}

.css-d8c1s9{
    margin: 0px !important;
    border: 1px solid #E5E7EB !important;
    border-radius: 0px !important;
}

.css-d8c1s9.Mui-selected{
  color : #fff !important;
  border: #7A4BFF !important;
  background-color: #7A4BFF !important;
}

.css-d8c1s9.Mui-selected:hover {
  background-color: #fff !important;
  color: #7A4BFF !important;
}

.css-dmuikh-MuiButtonBase-root-MuiPaginationItem-root {
    margin: 0px !important;
    border: 1px solid #E5E7EB !important;
    border-radius: 0px !important;
}

.paginate > ul > li:first-child > button{
    border : 1px solid #E5E7EB !important;
    border-radius: 50px 0px 0px 50px !important;
}

.paginate > ul > li:last-child > button{
    border : 1px solid #E5E7EB !important;
    border-radius: 0px 50px 50px 0px !important;
}

// .MuiPagination-ul css-wjh20t-MuiPagination-ul > li:first-child{
//     display: none;
//     border-radius: 50px 0px 0px 50px !important;
// }

// .MuiPagination-ul css-wjh20t-MuiPagination-ul > li:last-child{
//     border-radius: 0px 50px 50px 0px !important;
// }

.MuiPagination-root {
    .MuiPagination-ul {
       flex-wrap: nowrap;
       li {
         &:first-child {
           flex-basis: 100%;
           display: flex;
           justify-content: flex-start;
           align-items: center;
           > button::after {
             margin-left: 10px;
             content: 'previous';
           }
         }
         &:last-child {
           flex-basis: 100%;
           display: flex;
           justify-content: flex-end;
           align-items: center;
           > button::before {
             margin-right: 10px;
             content: 'next';
           }
         }
       }
     }
   }

