.competition-main{
    
    .button-style{
        background-color: $violet;
        color: $white;
        border-color: $white;
        border-radius: 12px;
    
        &:hover{
            background-color: $white;
            color: $black;
            border: 1px solid $violet;
        }
    }
    
    .page-heading{
        color: #000;
        font-weight: $fontweight-bold;
        font-size: 30px;
    }

    .applied-filter{
        background-color: #5F80F4;
        color: $white;
        padding: 10px 15px;
        border-radius: 12px;
    }
    
    .filter{
        background-color: #FFFFFF;
        color: #333336;
        padding: 10px 15px;
        border: 1px solid #D1D5DB;
        border-radius: 12px;
    } 

    .export{
        background-color: $violet;
        color: $white !important;
        font-weight: $fontweight-bold;
        font-size: $font-regular;
        padding: 13px 25px;
        border-radius: 12px;
        border: 1px solid $smoky-cream;
    
        &:hover{
            background-color: $white;
            border: 1px solid $violet;
            color: $black !important;
        }

        &:disabled{
            border: 1px solid $violet;
            background-color: $white;
            color: $violet;
        }
    }

    .refresh{
        font-size: 20px;
        font-weight: bolder;
        background-color: #FFFFFF;
        padding: 5px 10px !important;
        cursor: pointer;
        color: #000;
        border: 1px solid #D1D5DB;
        border-radius: 10px;

        &:active{
            transform: translateY(2px);
        }

    }

    .disabled-refresh{
        font-size: 20px;
        font-weight: bolder;
        background-color: #eee;
        padding: 5px 10px !important;
        color: #000;
        border: 1px solid #D1D5DB;
        border-radius: 10px;
        visibility: 0 !important;
    }

    .nav-accordian{
        font-weight: $fontweight-bold;
        .pills{
            color: #000;
            border: 1px solid #D1D5DB;
        }
        .accordian-pills{
            background-color: $violet;
            color: $white;
            border: 1px solid $smoky-cream;
            &:hover{
                color: $white;
            }
        }
    }

    .bracket{
        [dir="ltr"] .sc-furwcr:nth-child(odd)::after {
            border-right: none !important;
        }

        [dir="ltr"] .sc-furwcr:nth-child(even)::after {
            border-right: none !important;
        }
        [dir="ltr"] .sc-furwcr:nth-child(even)::before {
            right: -1.5em;
            display: none !important;
        }
        .gspVLA:nth-child(even)::after {
            display: none !important;
            top: -0.5px;
        }
        .gspVLA:nth-child(odd)::after {
            display: none !important;
            top: -0.5px;
        }
        .dblgKN{
            color: #000;
            font-weight: bold;
        }
    
        .jTzNkf{
            background-color: rgb(122, 75, 255);
            color: $white;
            font-weight: $fontweight-bold;
            letter-spacing: 1px;
            border-radius: 15px;
            .dDVnxg{
                padding: 5px 10px;
                text-align: center !important;
            }
        }
    }

    .table-custom{
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }

        .table-body{
            color: #333336;

            .success{
                color: #118C4F;
                font-weight: $fontweight-bold;
            }

            .warning{
                color: #FFAB00;
                font-weight: $fontweight-bold;
            }

            .danger{
                color: #FF734B;
                font-weight: $fontweight-bold;
            }

            .normal{
                color: #333336;
                font-weight: $fontweight-bold;
            }
        }
    
        .actions {
            color : $cool-grey;
    
            .icon-color{
                color : $black;
            }
    
            .archive{
                color : $red;
            }
        }
    
    }

}

.competitiondetail-modal{
    .competitiondetail-heading {
        font-size: 24px;
        font-weight: $fontweight-bolder;
        font-family: 'Inter', sans-serif;
        color: #111928;
    }

    .input-bg{
        background-color: #f5f8fb;
        padding: 8px 10px;
        border: 1px solid #E5E7EB;
        border-radius: 10px;
    }

    .competitiondetail-body{
        .body-title{
            font-size: 18px;
            color: #111928;
        }

        .input-bg{
            background-color: #f5f8fb;
            padding: 8px 10px;
            border: 1px solid #E5E7EB;
            border-radius: 10px;
        }

        .table-custom{
            .table-head{
                background-color: $vivid-orange;
                color: $white;
                
                .head-border-left{
                    border-top-left-radius: 15px;
                }
        
                .head-border-right{
                    border-top-right-radius: 15px;
                }
            }
    
            .table-body{
                color: #333336;
            }
        
        }

        
    }
        .competition-footer{
            justify-content: flex-start !important;
        }

}

.export{
    background-color: $violet;
    color: $white !important;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black !important;
    }

    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }
    
}