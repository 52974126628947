.create-competition, .edit-competition {
    .page-title{
        color: #111928;
        font-size: 20px;
        font-weight: $fontweight-bold;
    }

    .input_bg{
        background-color: #F9FAFB;
        border: 1px solid #E5E7EB;
        border-radius: 16px;
    }

    .input-file{
        input[type="file"]{
            display: none;
        }
        .label-btn{ 
            color: $flame-orange;
            background-color: $white;
            border: 1px solid $flame-orange;
            border-radius: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: $fontweight-bold;
            padding: 5px;
            cursor: pointer;
        }
    }

    .dblgKN{
        color: #000;
        font-weight: bold;
    }

    .jTzNkf{
        background-color: rgb(122, 75, 255);
        color: $white;
        font-weight: $fontweight-bold;
        letter-spacing: 1px;
        border-radius: 15px;
        .dDVnxg{
            padding: 5px 10px;
            text-align: center !important;
        }
    }

    .table-custom{
    
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }
    }

    .entries{
        background-color: #FF734B;
        padding: 5px 10px;
        border-radius: 12px;
        color: #fff;
        border: none;
    }

    .round-title {
        background-color: rgb(122, 75, 255);
        color: #fff;
        padding: 10px;
    }

}