.noUi-target {
    background: $soft;
    border-radius: $border-radius;
    border: $border-width solid $gray-500;
    box-shadow: inset 0 1px 1px $light, 0 3px 6px -5px $light;
}

.noUi-horizontal {
    height: $noui-slider-height;
    .noUi-handle {
        width        : 25px;
        height       : 25px;
        top          : -9px;
        border-radius: $noui-origin-border-radius;
        border: $border-width solid $noui-border-color;
        &:focus {
            box-shadow: $noui-focus-shadow;
            outline     : none;
            cursor      : pointer;
        }

        &::before {
            right: 8px;
            top:5px;
            background: $noui-border-color;
        }

        &::after {
            left: 8px;
            top:5px;
            background: $noui-border-color;
        }
    }
    .noUi-tooltip {
        bottom: 135%;
    }
}

.noUi-tooltip{
    color: $gray;
    font-size: $font-size-sm;
    border-radius: .5rem;
    border: $border-width solid $noui-border-color;
}

.noUi-connect {
    background: $noui-connect-bg;
}

.noUi-connects{
    border-radius: $border-radius;
}