.notification code, .notification pre {
  background: transparent !important;
  padding: 0 !important;
}


.qb-container {

    .button {
        background-color: #686eb7;
        color: #fff;
        border: none !important;
        padding: 5px 15px;
        margin: 5px 10px;
        border-radius: 25px !important;
        font-size: 0.9rem !important;
    }

    .rule-group {
        padding: 12px !important;
        border : 5px 1px 1px 1px solid #686eb7 !important;

        .queryBuilder-dragHandle{
          display: none !important;
        }

      .rule-group {
        background-color: #50505025 !important;
        // background-color: #f3f3f4 !important;
        border : 2px solid #686eb7 !important;
        border-top: 5px solid #686eb7 !important;
        margin: 10px 0px !important;
        // &:nth-child(2) {
        //   background-color: red !important;
        // }
    }

  }
  .rule {
    background-color: #e6eeff !important;
    // background-color: #f3f3f4 !important;
    // border: 1px solid #b4b4b4 !important;
    padding: 15px !important;
    margin: 0px 0 !important;
    display: flex !important;

    .css-5a7vsu-container{
      height: 2.5em !important;
      .css-wjj10o-control{
        min-height: 0px !important;
        // width: 120px !important;
        // height: 32px !important;
        // background-color: #fff !important;
        // margin: 0px 5px !important;
        // font-size: 13px !important;
        // border-radius: 5px !important;
        border: 1px solid #5A5A5A;
        height: 2.5em !important;
        width: 120px !important;
        padding-top: calc(0em - 1px) !important;
        background-color: #fff !important;
        color: #363636 !important;
        font-weight: normal !important;
        cursor: pointer !important;     
        outline: 0 !important;
        margin-right: 1em !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        border-radius: 10px !important;
      }
      .css-fckpdp-control{
        border: 1px solid #5A5A5A;
        height: 0em !important;
        width: 120px !important;
        background-color: #fff !important;
        color: #363636 !important;
        font-weight: normal !important;
        cursor: pointer !important;     
        outline: 0 !important;
        margin-right: 1em !important;
        font-size: 0.8rem !important;
        font-weight: bold !important;
        border-radius: 10px !important;
      }
    }

    .queryBuilder-dragHandle{
      display: none !important;
    }

    .rule-remove{
      margin: 0px 10px !important;
      padding: 0px 15px !important;
      border-radius: 25px !important;
    }

    .rule-fields, .rule-operators {
      border: none !important;
    }
  }

  .btn-add-rule {
    margin: 0 10px 0 0 !important;
  }
  
  .btn-add-group {
    margin: 10px !important;
  }

  .select {
    // border: 1px solid transparent !important;
    border: 1px solid #5A5A5A;
    box-shadow: none !important;
    height: 2.5em !important;
    line-height: 1.5 !important;
    padding-bottom: calc(.5em - 1px) !important;
    padding-left: calc(.75em - 1px) !important;
    padding-right: calc(.75em - 1px) !important;
    padding-top: calc(.5em - 1px) !important;
    position: relative !important;
    background-color: #fff !important;
    color: #363636 !important;
    cursor: pointer !important;
    display: block !important;
    max-width: 100% !important;
    outline: 0 !important;
    margin-right: 1em !important;
    border-radius: 2px !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
    border-radius: 10px !important;
  }
  .select-combinator {
    margin: 0 0 10px 0 !important;
  }


  .input-value {
    max-width: 50% !important;
    margin-right: 1em !important;
    border: none !important;
  }
}