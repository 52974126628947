.mainLeaderboard{
    
    .page-heading{
        color: #000;
        font-weight: $fontweight-bold;
        font-size: 30px;
    }

    .applied-filter{
        background-color: #5F80F4;
        color: $white;
        padding: 10px 15px;
        border-radius: 12px;
    }
    
    .filter{
        background-color: #FFFFFF;
        color: #333336;
        padding: 10px 15px;
        border: 1px solid #D1D5DB;
        border-radius: 12px;
    } 

    .nav-accordian{
        font-weight: $fontweight-bold;

        .pills{
            color: #000;
            border: 1px solid #D1D5DB;
        }

        .accordian-pills{
            background-color: $violet;
            color: $white;
            border: 1px solid $smoky-cream;
            &:hover{
                color: $white;
            }
        }
    }

    .refresh{
        font-size: 20px;
        font-weight: bolder;
        background-color: #FFFFFF;
        padding: 5px 10px !important;
        cursor: pointer;
        color: #000;
        border: 1px solid #D1D5DB;
        border-radius: 10px;

        &:active{
            transform: translateY(2px);
        }

    }

    .table-custom{
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }

        .table-body{
            color: #333336;
        }
    
        .actions {
            color : $cool-grey;
    
            .icon-color{
                color : $black;
            }
    
            .archive{
                color : $red;
            }
        }
    
    }

    .leadeboard-footer{
        justify-content: flex-start !important;
    }

    .entries{
        background-color: #FF734B;
        padding: 5px 10px;
        border-radius: 12px;
        color: #fff;
        border: none;
    }
    
}

.export{
    background-color: $violet;
    color: $white !important;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black !important;
    }

    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }
}
