.version{

    .page-heading{
        color: #000;
        font-weight: $fontweight-bold;
        font-size: 30px;
    }

    .applied-filter{
        background-color: #5F80F4;
        color: $white;
        padding: 10px 15px;
        border-radius: 12px;
    }
    
    .filter{
        background-color: #FFFFFF;
        color: #333336;
        padding: 10px 15px;
        border: 1px solid #D1D5DB;
        border-radius: 12px;
    } 

    .button-style{
        background-color: $violet;
        color: $white;
        border-color: $white;
        border-radius: 12px;
    
        &:hover{
            background-color: $white;
            color: $black;
            border: 1px solid $violet;
        }
    }
    
    .table-custom{
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }

        .table-body{
            color: #333336;
        }
    
    }
}

.export{
    background-color: $violet;
    color: $white !important;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black !important;
    }

    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }
    
}

.ModalCustom{
    .modal_body{
        .input-file{
            input[type="file"]{
                display: none;
            }
            .label-btn{ 
                color: $flame-orange;
                background-color: $white;
                border: 1px solid $flame-orange;
                border-radius: 10px;
                margin-top: 5px;
                font-size: 12px;
                font-weight: $fontweight-bold;
                padding: 5px;
                cursor: pointer;
            }
            .fileName{
                font-size: 12px;
                font-style: italic;
                font-weight: bold;
                margin: 0px 0px 0px 10px;
            }
        }
    }
}