.live-editor, .navbar-search{
    display: none !important;
}

.ab{
    overflow: hidden !important;
}


.new_modal{ 
    position: absolute;
    max-width: 80vw !important;
    width: 70vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.env-modal{ 
    position: absolute;
    max-width: 80vw !important;
    width: 40vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.bottom-border{
    width: 30px;
    height: 3px !important;
    background-color : #61DAFB;
    margin: 3px 0px 10px 0px !important
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #61DAFB !important;
}

.Bg-Color{
    background-color: #61DAFB;
    color: #000;
}

.nav-main:nth-child(2) {
    text-align: center;
}

.fixed{
    position: sticky !important;
    top: 0% !important;
    z-index: 999;
    background-color: #f5f8fb;
}

.exportBtn:hover {
    background-color: #61DAFB !important;
    color: #000 !important;
} 

.btn-font {
    font-family: Nunito Sans !important;
    font-size: 12px !important;
}

.btn-active{
    color: rgb(61, 145, 255) !important;
    background-color: #fff !important;
    font-size: 12px;
}

.bg-img{
    background-image: url('../Images/chacter_poster_v002_t001.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.login_Box{
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 30%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255, 255, 0.6);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.9);
}

@media (min-width:320px) and (max-width: 768px) {
    .bg-img{
        background-image: url('../Images/chacter_poster_v002_t001.jpg');
        background-position: center;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        position: relative;
    }
    .login_Box{
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        width: 70%;
        transform: translate(-50%, -50%);
        background-color: rgba(255,255, 255, 0.6);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.9);
    }
} 

@media (min-width:769px) and (max-width: 1024px) {
    .bg-img{
        background-image: url('../Images/chacter_poster_v002_t001.jpg');
        background-position: center;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        position: relative;
    }
    .login_Box{
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        width: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255,255, 255, 0.6);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.9);
    }
} 

/* .calendar{
    border: 1px solid #000;
} */

.cal{
    border-radius: 10px !important;
    width: 60px;
    height: 60px;
    font-family: 'Inter', sans-serif;
}

.cal-month{
    background-color: #FF2D19 !important;
    color: #fff !important;
    padding: 2px 0px !important;
    text-align: center !important;
    border-radius: 10px 10px 0px 0px !important;
    font-size: 14px;
    font-weight: 700;
}

.cal-day{
    color: #333336 !important;
    font-weight: 700;
    background-color: #F9FAFB !important;
    padding: 5px 0px !important;
    text-align: center !important;
    font-size: 25px !important;
    border-radius: 0px 0px 10px 10px !important;
}

.btn-state {
    background-color: #7A4BFF !important;
    color: #fff !important;
    border: none !important;
}

.btn-color {
    color: #3d91ff !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
    border: none !important;
}

.css-1480iag-MuiInputBase-root-MuiInput-root::after {
    border: none !important;
}

/* .ml{
    margin-left: 72px !important;
} */

.percentage{
    width: 100px;
    height: 10px;
    background-color: #eee;
    border-radius: 25px;
    position: relative;
    padding: 0px;
}

.successfull{
    position: absolute;
    left: 0;
    width: 0px;
    height: 10px;
    background-color: green;
    border-radius: 25px;
    padding: 0px;
}

.progress-bar{
    margin: 5px 0px 0px 0px !important;
    background-color: #fff !important;
    color: #000 !important;
    text-align: left !important;
    font-size: 13px !important;
    font-style: italic !important;
    font-weight: bold !important;
    display: block !important;
}