.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 1px 4px 0px 0px !important;
}

.dropzone-files{
    margin: 0px 0px 5px 0px;
}

/* body{
    position: relative;
} */

.live-toggle{
    width: 100%;
    position: absolute;
    top: 80%;
    left: 80%;
    transform: translate(-80%, -80%);
}

.inside-mod{
    background-color: #F5F5F5;
    border: 5px solid #eee;
}

.MuiGrid-root.MuiDropzonePreviewList-imageContainer.MuiGrid-item.MuiGrid-grid-xs-4 {
    max-width: fit-content !important;
}


.MuiOutlinedInput-input {
    padding: 10px 14px !important;
}

.MuiDropzoneArea-root {
    min-height: 20px !important;
}

.MuiTypography-h5 {
    font-size: 1rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
    margin: 10px !important;
}
.MuiDropzoneArea-icon {
    color: rgba(0, 0, 0, 0.87);
    width: 40px !important;
    height: 40px !important;
    margin: 0px 0px 10px 0px !important;
}

.MuiChip-outlined{
    border: 1px solid rgba(3, 236, 15, 0.726);
    background-color: transparent;
}

.flex_display{
	display : flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold !important;
}

.Loading_One{
  width: 8px;
  height: 8px;
  border-radius : 50%;
  background: #000;
  position: relative;
  animation: mymoveOne 1s infinite;
  margin : 10px;
  opacity : 1
}

.Loading_Two{
  width: 8px;
  height: 8px;
  border-radius : 50%;
  background: #000;
  position: relative;
  animation: mymoveTwo 1s infinite;
  margin : 10px;
  opacity : 0.1;
}

.Loading_Three{
  width: 8px;
  height: 8px;
  border-radius : 50%;
  background: #000;
  position: relative;
  animation: mymoveThree 1s infinite;
  margin : 10px;
  opacity : 0.1;
}


@keyframes mymoveOne {
  0%   		{opacity : 1;}
  20%   	{opacity : 0.1;}
  40%   	{opacity : 0.1;}
  60%   	{opacity : 1}
  80%   	{opacity : 0.1;}
  100% 		{opacity : 0.1;}
}

@keyframes mymoveTwo {
  0%   		{opacity : 0.1;}
  20%   	{opacity : 1;}
  40%   	{opacity : 0.1;}
  60%   	{opacity : 0.1}
  80%   	{opacity : 1;}
  100% 		{opacity : 0.1;}
}

@keyframes mymoveThree {
  0%   		{opacity : 0.1;}
  20%   	{opacity : 0.1;}
  40%   	{opacity : 1;}
  60%   	{opacity : 0.1}
  80%   	{opacity : 0.1;}
  100% 		{opacity : 1;}
}