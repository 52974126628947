.filter-main{
    .heading-title{
        color: #111928;
        font-size: 24px;
        font-weight: $fontweight-bold;
        display: inline;
    }
    .filter-title{
        font-size: 17px;
        font-weight: 600;
        color: #111928;
        font-weight: 600;
    }
    .filter-label{
        font-size: 10px !important;
    }
    .filter-footer{
        justify-content: flex-start !important;
    }
}