.modal{
    .modal-body{
        .main-dev{

            .env-outer-dev{
                padding: 20px 0px;
                background-color: #f1effe;
                margin: 10px 0px;
                border-radius: 15px !important;

                .development{
                    border-right : 4px solid #7762f6;
                    background-color: #f1effe;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }

            }

            .env-outer-dev-selected{
                border : 2px solid #7762f6;
                padding: 20px 0px;
                background-color: #f1effe;
                margin: 10px 0px;
                border-radius: 15px !important;

                .development{
                    border-right : 4px solid #7762f6;
                    background-color: #f1effe;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }
            }

        }

        .main-staging{

            .env-outer-staging{

                padding: 20px 0px;
                background-color: #fcf0ed;
                margin: 10px 0px;
                border-radius: 15px !important;

                .staging{
                    border-right : 4px solid #e5734c;
                    background-color: #fcf0ed;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }

            }

            .env-outer-staging-selected{
                border : 2px solid #e5734c;
                padding: 20px 0px;
                background-color: #fcf0ed;
                margin: 10px 0px;
                border-radius: 15px !important;

                .staging{
                    border-right : 4px solid #e5734c;
                    background-color: #fcf0ed;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }
            }

        }

        .main-production{

            .env-outer-production{

                padding: 20px 0px;
                background-color: #f0fbef;
                margin: 10px 0px;
                border-radius: 15px !important;

                .production{
                    border-right : 4px solid #74d068;
                    background-color: #f0fbef;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }

            }

            .env-outer-production-selected{

                padding: 20px 0px;
                background-color: #f0fbef;
                margin: 10px 0px;
                border-radius: 15px !important;
                border : 2px solid #74d068;

                .production{
                    border-right : 4px solid #74d068;
                    background-color: #f0fbef;
                    cursor: pointer !important;
                    margin: 0px 15px;
        
                    p{
                        font-size: 14px !important;
                        line-height: 1.4rem !important;
                    }
        
                }

            }

        }
        
    }

    .modal-footer{
        .Button-custom{
            background-color: $violet;
            color: $white;
            border: none;

            &:hover{
                background-color: $white;
                border: 1px solid $violet;
                color: $violet;
            }
        }

    }
}