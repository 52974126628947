.gamestarz{

        .page-heading{
            color: #000;
            font-weight: $fontweight-bold;
            font-size: 30px;
        }

        .applied-filter{
            background-color: #5F80F4;
            color: $white;
            padding: 10px 15px;
            border-radius: 12px;
        }
        
        .filter{
            background-color: #FFFFFF;
            color: #333336;
            padding: 10px 15px;
            border: 1px solid #D1D5DB;
            border-radius: 12px;
        } 

        .button-style{
            background-color: $violet;
            color: $white;
            border-color: $white;
            border-radius: 12px;
        
            &:hover{
                background-color: $white;
                color: $black;
                border: 1px solid $violet;
            }
        }

        .export{
            background-color: $violet;
            color: $white !important;
            font-weight: $fontweight-bold;
            font-size: $font-regular;
            padding: 13px 25px;
            border-radius: 12px;
            border: 1px solid $smoky-cream;
        
            &:hover{
                background-color: $white;
                border: 1px solid $violet;
                color: $black !important;
            }

            &:disabled{
                border: 1px solid $violet;
                background-color: $white;
                color: $violet;
            }
        }

        .transaction-history{
            .table-custom{
                .table-head{
                    background-color: $vivid-orange;
                    color: $white;
                    
                    .head-border-left{
                        border-top-left-radius: 15px;
                    }
            
                    .head-border-right{
                        border-top-right-radius: 15px;
                    }
                }
        
                .table-body{
                    color: #333336;
                    .success{
                        color: #118C4F;
                        font-weight: $fontweight-bold;
                    }
        
                    .danger{
                        color: #FF734B;
                        font-weight: $fontweight-bold;
                    }
                }
            
            }
        }

        .coupon{

            .table-custom{
                .table-head{
                    background-color: $vivid-orange;
                    color: $white;
                    
                    .head-border-left{
                        border-top-left-radius: 15px;
                    }
            
                    .head-border-right{
                        border-top-right-radius: 15px;
                    }
                }
        
                .table-body{
                    color: #333336;
                    .success{
                        color: #118C4F;
                        font-weight: $fontweight-bold;
                    }
        
                    .danger{
                        color: #FF734B;
                        font-weight: $fontweight-bold;
                    }
                }
            
            }

            .input_bg{
                background-color: #F9FAFB;
                border: 1px solid #E5E7EB;
                border-radius: 16px;
            }

        }

        .scratch-card{
            .table-custom{
                .table-head{
                    background-color: $vivid-orange;
                    color: $white;
                    
                    .head-border-left{
                        border-top-left-radius: 15px;
                    }
            
                    .head-border-right{
                        border-top-right-radius: 15px;
                    }
                }
        
                .table-body{
                    color: #333336;
                    .success{
                        color: #118C4F;
                        font-weight: $fontweight-bold;
                    }
        
                    .danger{
                        color: #FF734B;
                        font-weight: $fontweight-bold;
                    }
                }
            
            }

            .input_bg{
                background-color: #F9FAFB;
                border: 1px solid #E5E7EB;
                border-radius: 16px;
            }
            
        }

        .payout{
            .table-custom{
                .table-head{
                    background-color: $vivid-orange;
                    color: $white;
                    
                    .head-border-left{
                        border-top-left-radius: 15px;
                    }
            
                    .head-border-right{
                        border-top-right-radius: 15px;
                    }
                }
        
                .table-body{
                    color: #333336;
                }
            
            }

            .input_bg{
                background-color: #F9FAFB;
                border: 1px solid #E5E7EB;
                border-radius: 16px;
            }

            .card{
                padding: 0px !important;
                border : 1px solid #eee !important;
                
                .card-header{
                    padding: 10px 10px 0px 10px !important;
                    margin: 0px !important;

                    .card-head-info{
                        display: flex !important;
                        justify-content: space-between !important;
                        align-items: center !important;

                        h5{
                            margin: 5px 0px !important;
                        }
                    }

                }

                .card-footer{
                    .card-footer-info{
                        display: flex !important;
                        justify-content: space-between !important;
                        align-items: center !important;
                        font-style: italic;
                    }
                }

            }
            .jodit-container:not(.jodit_inline) { position: relative; min-height: 100px; }
            .jodit-toolbar__box:not(:empty):not(:empty) { width: 100%; z-index: 99; position: sticky; background-color: #f9f9f9; top: 70px !important; }
        }

}

.gamestarz-modal{
    .coupondetail-modal{
        .coupon-head{
            font-size: 24px;
            font-weight: $fontweight-bolder;
            font-family: 'Inter', sans-serif;
            color: #111928;
        }
        .coupon-body{
            .body-title{
                font-size: 18px;
                color: #111928;
            }
    
            .input-bg{
                background-color: #f5f8fb;
                padding: 8px 10px;
                border: 1px solid #E5E7EB;
                border-radius: 10px;
            }
        }
        .cououndetail-footer{
            justify-content: flex-start !important;
        }
    }

    .scrachcard-modal{
        .sc-head{
            font-size: 24px;
            font-weight: $fontweight-bolder;
            font-family: 'Inter', sans-serif;
            color: #111928;
        }
        .sc-body{
            .body-title{
                font-size: 18px;
                color: #111928;
            }
    
            .input-bg{
                background-color: #f5f8fb;
                padding: 8px 10px;
                border: 1px solid #E5E7EB;
                border-radius: 10px;
            }
        }
        .sc-footer{
            justify-content: flex-start !important;
        }
    }
}

.export{
    background-color: $violet;
    color: $white !important;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black !important;
    }
    
    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }
}