// .preloader {
//     display: flex;
//     align-items: center;
//     height: 100vh;
//     left: 0;
//     position: fixed;
//     top: 0;
//     width: 100%;
//     z-index: 9999;
//     @include transition(height .3s);

//     .loader-element {

//         @include transition(spin .3s linear infinite);
//         -webkit-animation:spin 3s linear infinite;
//         -moz-animation:spin 3s linear infinite;
//         animation:spin 3s linear infinite;
//     }

//     &.show {
//         height: 0;

//         .loader-element {
//             display: none !important;
//         }
//     }
// }

// @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
// @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
// @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background-color: #fff !important;
    @include transition(height .6s);

    svg{
        position: relative;
        width: 150px;
        height: 150px;
        @include transition(ring .3s linear infinite);
        animation-timing-function: ring 1.5s linear infinite;

        circle {
            width: 100%;
            height: 100%;
            /* fill: none;
            stroke-width: 8;
            stroke: #7A4BFF; */
            transform: translate(5px, 5px);
            stroke-linecap: round;
            stroke-dasharray: 200;
            stroke-dashoffset: 200;
            animation: animate 3s linear infinite;
        }

    }

        &.show {
        height: 0;
        svg{
            display: none !important;
        }
    }
  
}

@keyframes ring {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes animate {
    0%, 100%{
        stroke-dashoffset: 200;
    }
    50%{
        stroke-dashoffset: 0;
    }
    50.1%{
        stroke-dashoffset: 400;
    }
}
