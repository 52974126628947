.add-game, .edit-game{

    .input_bg{
        background-color: #F9FAFB;
        border: 1px solid #E5E7EB;
        border-radius: 16px;
    }

    .game-heading {
        color: #111928;
        font-size: 20px;
        font-weight: $fontweight-bold;
    }

    .match{
        .add-match{
            background-color: $white;
            color: $violet;
            border: none;
            &:hover{
                color: #000;
            }
        }
    }

    .table-custom{
    
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }
    
        .actions {
            color : $cool-grey;
    
            .icon-color{
                color : $black;
            }
    
            .archive{
                color : $red;
            }
        }
    
    }

    .right-label-heading{
        color: #111928;
        font-size: 20px;
        font-weight: $fontweight-bold;
    }

    .input-file{
        input[type="file"]{
            display: none;
        }
        .label-warn{
            font-size: 12px;
        }
        .label-btn{ 
            color: $flame-orange;
            background-color: $white;
            border: 1px solid $flame-orange;
            border-radius: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: $fontweight-bold;
            padding: 5px;
            cursor: pointer;
        }
    }
    
}