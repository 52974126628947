.button-style{
    background-color: $violet;
    color: $white;
    border-color: $white;
    border-radius: 12px;

    &:hover{
        background-color: $white;
        color: $black;
        border: 1px solid $violet;
    }
}

.export{
    background-color: $violet;
    color: $white;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black;
    }
    
    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }

}

.game-page{
    .page-heading{
        color: #000;
        font-weight: $fontweight-bold;
        font-size: 30px;
    }

    .applied-filter{
        background-color: #5F80F4;
        color: $white;
        padding: 10px 15px;
        border-radius: 12px;
    }
    
    .filter{
        background-color: #FFFFFF;
        color: #333336;
        padding: 10px 15px;
        border: 1px solid #D1D5DB;
        border-radius: 12px;
    } 
    
    .table-custom{
    
        .table-head{
            background-color: $vivid-orange;
            color: $white;
            
            .head-border-left{
                border-top-left-radius: 15px;
            }
    
            .head-border-right{
                border-top-right-radius: 15px;
            }
        }

        .table-body{
            color: #333336;
        }
    
        .actions {
            color : $cool-grey;
    
            .icon-color{
                color : $black;
            }
    
            .archive{
                color : $red;
            }
        }
    
    }
    
    .nav-accordian{
        font-weight: $fontweight-bold;
        .pills{
            color: #000;
            border: 1px solid #D1D5DB;
        }
        .accordian-pills{
            background-color: $violet;
            color: $white;
            border: 1px solid $smoky-cream;
            &:hover{
                color: $white;
            }
        }
    }
}

.gamedetail-model{
    .gamedetail-heading {
        font-size: 24px;
        font-weight: $fontweight-bolder;
        font-family: 'Inter', sans-serif;
        color: #111928;
    }

    .gamedetail-body{
        .body-title{
            font-size: 18px;
            color: #111928;
        }

        .input-bg{
            background-color: #f5f8fb;
            padding: 8px 10px;
            border: 1px solid #E5E7EB;
            border-radius: 10px;
        }

        .download-btn{
            background-color: $white;
            padding: 7px 11px;
            border: 2px solid $vivid-orange;
            border-radius: 12px;
            color: $vivid-orange;

            &:hover{
                background-color: $vivid-orange;
                color: $white
            }
        }

    }

    .gamedetail-footer{
        justify-content: flex-start !important;
    }
}