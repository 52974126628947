.referrals{
    .page-heading{
        color: #000;
        font-weight: $fontweight-bold;
        font-size: 30px;
    }

    .applied-filter{
        background-color: #5F80F4;
        color: $white;
        padding: 10px 15px;
        border-radius: 12px;
    }
    
    .filter{
        background-color: #FFFFFF;
        color: #333336;
        padding: 10px 15px;
        border: 1px solid #D1D5DB;
        border-radius: 12px;
    } 

    .button-style{
        background-color: $violet;
        color: $white;
        border-color: $white;
        border-radius: 12px;
    
        &:hover{
            background-color: $white;
            color: $black;
            border: 1px solid $violet;
        }
    }
}

.export{
    background-color: $violet;
    color: $white !important;
    font-weight: $fontweight-bold;
    font-size: $font-regular;
    padding: 13px 25px;
    border-radius: 12px;
    border: 1px solid $smoky-cream;

    &:hover{
        background-color: $white;
        border: 1px solid $violet;
        color: $black !important;
    }

    &:disabled{
        border: 1px solid $violet;
        background-color: $white;
        color: $violet;
    }
    
}